<template>
    <div class="page"> 
      <h1>Home View</h1>
    </div>
</template>
  

<script>

import { ethers } from 'ethers';

// function toEth(num, round = 2) {
//     num = num / (10 ** 18);
//     var p = Math.pow(10, round);
//     var n = num * p;
//     return Math.floor(n) / p;
// }

// function toWei(num) {
//     return window.web3.utils.toWei(''+num,'ether')        

// }


export default {
    data() {
        return {
        }
    },
    methods: {

        // Function to get the current network ID
        getChainId() {
            // Create a provider using the current Ethereum provider (e.g., MetaMask)
            const provider = new ethers.providers.Web3Provider(window.ethereum);

            // Get the network and extract the network ID
            return provider.getNetwork().then(network => {
                console.log('Current chainId', network.chainId)
                return network.chainId;
            }).catch(error => {
                console.error('Error getting network:', error);
                throw error;
            });
        }

    },

    mounted() {
        this.getChainId()
    }

}
</script>

<style scoped>

    td {
        padding-right: 10px;
    }
    .section {
        width: fit-content;
        margin-top:20px;
        margin-bottom:20px;
        padding: 10px;
        font-weight:bold;
        font-style:oblique;
        border: 1px solid white;
        
    }
    .title{
        background-color: darkcyan;
        color:black;
        padding: 3px;
    }
</style>
