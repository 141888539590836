<template>
  <div class="container">
    <h2>Wallet</h2>
    <div class="content">
      <table>
        <tbody>
          <tr v-for="(token, tokenId) in wallet" :key="tokenId" class="wallet-item">
            <td><img style='width:25px;height:25px;' :src="'images/' + tokenId + '.png'"></td>
            <td style="text-transform: uppercase;">
              <number :value="token.amount ? token.amount : 0"></number> {{ token.tokenName }} / {{token.chainName}}
              {{fee(token, 1).userFee.toFixed(4)}}
            </td>
            <td>
              <button @click="deposit(token)" :disabled="!token.depositEnabled" class="deposit-button">Deposit</button>
              <button @click="withdraw(token)" :disabled="!token.withdrawEnabled"
                class="withdraw-button">Withdraw</button>
            </td>
          </tr>
        </tbody>
      </table>
      <WithdrawDialog ref="withdrawDialog" />
      <DepositDialog ref="depositDialog" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Number from './AnimateNumber.vue';
import { ethers } from 'ethers';
import DepositDialog from './DepositDialog.vue';
import WithdrawDialog from './WithdrawDialog.vue';
import api from '../lib/api';


export default {
  components: {
    number: Number,
    DepositDialog,
    WithdrawDialog
  },
  computed: {
    ...mapGetters(['wallet', 'token']),
  },
  methods: {
    async getChainId() {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        console.log('Current chainId', network.chainId);
        return network.chainId;
      } catch (error) {
        console.error('Error getting network:', error);
        throw error;
      }
    },
    async deposit(token) {
      console.log('Deposit', token);
      try {
        const chainId = await this.getChainId();
        if (chainId === token.chainId) {
          const amount = await this.$refs.depositDialog.open({
            title: 'Deposit Amount',
            message: `Enter amount to deposit for ${token.tokenName}:`
          });

          if (amount) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []); 
            const signer = provider.getSigner();
            const address = await signer.getAddress(); 
            console.log('Address:', address);

            const value = ethers.utils.parseUnits(amount, token.tokenUnit || 'ether'); 

            if (token.nativeToken) {
              const tx = {
                to: token.toAddress, 
                value: value
              };
              const transaction = await signer.sendTransaction(tx);
              console.log('Transaction:', transaction);
              this.$toast.success('Transaction sent! Waiting for confirmation...');
              await transaction.wait();
              this.$toast.success('Transaction confirmed!');

            } else {
              const contract = new ethers.Contract(token.contractAddress, [
                "function transfer(address to, uint256 amount) returns (bool)"
              ], signer);

              const tx = await contract.populateTransaction.transfer(token.toAddress, value);

              const gasLimit = await contract.estimateGas.transfer(token.toAddress, value);

              const transaction = await signer.sendTransaction({
                to: contract.address,
                data: tx.data,
                gasLimit: gasLimit,
              });

              console.log('Transaction:', transaction);
              this.$toast.success('Transaction sent! Waiting for confirmation...');
              await transaction.wait();
              this.$toast.success('Transaction confirmed!');
            }
          }
        } else {
          this.$toast.error('Please switch to the correct network in MetaMask.');
        }
      } catch (error) {
        // console.error('Deposit canceled');
        // this.$toast.error('Error during deposit. Check console for details.');
      }
    },
    async withdraw(token) {
      console.log('Withdraw', token);
      this.currentToken = token;
      
      try {
        const { amount } = await this.$refs.withdrawDialog.open({
          token
        });
        
        console.log(`Withdraw Amount: ${amount}`);
        
        // Call the API to initiate the withdrawal
        const response = await api.post('/withdraw/init', {
          tokenId: token.tokenId,
          amount: amount,
        });

        console.log(response.data)
        if (!response.data.error) {
          this.$toast.success('Withdrawal initiated successfully!');
        } else {
          this.$toast.error(response.data.message);
        }
        
      } catch (error) {
        // console.log('Withdrawal canceled');
        // console.log(error)
      }
    },
    fee(token, amount) {
      const gasPrice = token.gasPrice || 0;
      const gasPriceX = token.gasPriceX || 1; // Assuming 1 if not available, as in the SQL logic
      const gasLimit = token.gasLimit || 0;
      const minWithdrawFee = token.minWithdrawFee || 0;
      const tokenPrice = token.tokenPrice || 1; // Assume 1 if token price is not available

      // Step 1: Calculate the transaction fee (same as v_transaction_fee in SQL)
      const transactionFee = gasPrice * gasPriceX * gasLimit;

      // Step 2: Calculate the user fee, which is the greater of the transaction fee times the token price or the minimum withdrawal fee
      const userFee = Math.max(transactionFee * tokenPrice, minWithdrawFee);

      // Step 3: Calculate the withdrawable amount (same as v_withdraw_amount in SQL)
      const withdrawAmount = amount - userFee;

      return { userFee, withdrawAmount };
    }

  },
};
</script>

<style scoped>

h2 {
  color: #ffffff;
  /* Match header color */
}

table {
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #444444;
  /* Match table row border color */
}

.wallet-item {
  background-color: #3a3a3a;
  /* Match table row background color */
}

.withdraw-button {
  background-color: #007bff;
  /* Match withdraw button color */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.withdraw-button:disabled {
  background-color: #6c757d;
  /* Match disabled button color */
  cursor: not-allowed;
}

.deposit-button {
  background-color: #28a745;
  /* Match deposit button color */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 5px;
}

.deposit-button:disabled {
  background-color: #6c757d;
  /* Match disabled button color */
  cursor: not-allowed;
}
</style>
