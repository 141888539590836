<template>
  <span>{{ formattedNumber }}</span>
</template>

<script>
export default {
  data() {
    return {
      displayNumber: 0,
      counter: null,
      maxDecimalPlaces: 0
    };
  },
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    animate: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value() {
      const newValue = parseFloat(this.value) || 0; // Parse the value to a number, default to 0 if parsing fails
      this.maxDecimalPlaces = this.getDecimalPlaces(newValue);
      // console.log('maxDecimalPlaces', this.maxDecimalPlaces);
      // console.log('Number prop changed:', newValue);
      if (!this.animate) {
        this.displayNumber = newValue;
        return;
      }

      clearInterval(this.counter);
      if (newValue === this.displayNumber) {
        return;
      }
      this.counter = setInterval(() => {
        let change = (newValue - this.displayNumber) / 5;
        this.displayNumber += change;

        if (Math.abs(newValue - this.displayNumber) < Math.pow(10, -this.maxDecimalPlaces)) {
          this.displayNumber = newValue;
          clearInterval(this.counter);
        }
      }, 20);
    }
  },
  computed: {
    formattedNumber() {
      return this.formatNumber(this.displayNumber);
    }
  },
  methods: {
    getDecimalPlaces(number) {
      const parts = number.toString().split('.');
      return parts.length > 1 ? parts[1].length : 0;
    },
    // formatNumber(number) {
    //   if (number === 0) return '0'; // Explicitly handle the case where the number is 0
    //   return number.toFixed(this.maxDecimalPlaces).replace(/\.?0+$/, '');
    // }
    formatNumber(number) {
      if (number === 0) return '0'; // Explicitly handle the case where the number is 0

      // Check if the number is an integer, if so, just return the number as it is
      if (Number.isInteger(number)) {
        return number.toString();
      }

      // Otherwise, handle decimal formatting
      return number.toFixed(this.maxDecimalPlaces).replace(/\.?0+$/, '');
    }

  },
  mounted() {
    const initialValue = parseFloat(this.value) || 0; // Parse the initial value to a number, default to 0 if parsing fails
    this.maxDecimalPlaces = this.getDecimalPlaces(initialValue);
    this.displayNumber = initialValue;
    // console.log('Component mounted, initial displayNumber:', this.displayNumber);
  }
};
</script>
