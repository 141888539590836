<!-- Dialog.vue -->
<template>
    <div v-if="visible" class="dialog-overlay">
      <div class="dialog">
        <h3>{{ title }}</h3>
        <div class='content'>
          <p>{{ message }}</p>
          <input v-model="inputValue" type="text" class="dialog-input" />
          <div class="dialog-buttons">
            <button @click="confirm" class="dialog-button confirm">Confirm</button>
            <button @click="cancel" class="dialog-button cancel">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false,
        title: '',
        message: '',
        inputValue: '',
        resolve: null,
        reject: null
      };
    },
    methods: {
      open({ title, message }) {
        this.title = title;
        this.message = message;
        this.visible = true;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      confirm() {
        this.resolve(this.inputValue);
        this.visible = false;
      },
      cancel() {
        this.reject();
        this.visible = false;
      }
    }
  };
  </script>
