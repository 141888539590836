<!-- UserProfile.vue -->
<template>
    <div class="container">
      <h2>User Profile</h2>
      <div class="content">
          <div v-if="profile">
            <p><strong>Public Address:</strong> {{ profile.publicAddress }}</p>
          </div>
          <div v-else>
            <p>Loading profile data...</p>
          </div>
        </div>
    </div>
  </template>
  

<script>
// UserProfile.vue
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['profile']),
  },
};
</script>
