import Vue from 'vue';
import store from './store';

class Socket {
  constructor() {
    this.socket = null;
    this.auth = null;
  }

  connect() {

    this.auth = store.getters.auth;

    console.log('Websocket url:', process.env.VUE_APP_SOCKET_URL)

    this.socket = new WebSocket(process.env.VUE_APP_SOCKET_URL);


    this.socket.onopen = () => {
      console.log('Websocket connected');
      this.socket.send(JSON.stringify({ token:this.auth }));

      this.pingInterval = setInterval(() => {
        if (this.socket.readyState === WebSocket.OPEN) {
          this.socket.send(JSON.stringify({ type: 'ping' }));
        }
      }, 5000);
    };

    this.socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type!='gas_price') {
        console.log('Websocket received:', message);
      }

      if (message.type === 'error') {
        if (message.code === 'invalid_token') {
          store.commit('clearAuth');
        }
      }
      if (message.type === 'deposit') {
        switch (message.status) {
          case 'pending':
            Vue.$toast.info(`Incoming deposit: ${message.amount} ${message.token.name.toUpperCase()}`);
            break;
          case 'confirmed':
            Vue.$toast.success(`Deposit confirmed: ${message.amount} ${message.token.name.toUpperCase()}`);
            break;
          case 'reverted':
            Vue.$toast.error(`Deposit reverted: ${message.amount} ${message.token.name.toUpperCase()}`);
            break;
          default:
            console.warn(`Unknown deposit status: ${message.status}`);
        }
      } else if (message.type === 'withdraw') {
        switch (message.status) {
          case 'pending':
            Vue.$toast.info(`Withdraw pending: ${message.amount} ${message.token.name.toUpperCase()}`);
            break;
          case 'confirmed':
            Vue.$toast.success(`Withdraw confirmed: ${message.amount} ${message.token.name.toUpperCase()}`);
            break;
          case 'reverted':
            Vue.$toast.error(`Withdraw reverted: ${message.amount} ${message.token.name.toUpperCase()}`);
            break;
          default:
            console.warn(`Unknown withdraw status: ${message.status}`);
        }
      } else if (message.type === 'balance') {
        store.commit('setBalance', { token: message.token.id, amount: message.amount });
      } else if (message.type === 'gas_price') {
        store.commit('setGasPrice', { chain: message.chain_id, value: message.value });
      }      
    };

    this.socket.onerror = (err) => {
      console.error('Websocket error:', err);
    };

    this.socket.onclose = () => {
      console.log('Websocket closed');
      clearInterval(this.pingInterval);

      if (this.retryTimeout) {
        clearTimeout(this.retryTimeout);
      }
      if (this.auth){
        this.retryTimeout = setTimeout(() => {
          console.log('Retrying websocket connection...');
          this.connect(this.auth);
        }, 1000);
      }

    };
  }


  close() {
    if (this.socket) {
      this.socket.close();
      clearInterval(this.pingInterval);
    }
  }
}

const socket = new Socket();
export default socket;
