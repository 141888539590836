<template>
    <div v-if="visible" class="dialog-overlay">
      <div class="dialog">
        <h3>Withdraw</h3>
        <div class="content">
          <p>Enter <span style="text-transform: uppercase;">{{ token.tokenName }}</span> amount to withdraw on <span style="text-transform: uppercase;">{{ token.chainName}}</span> chain on your address</p>
          <input v-model="inputAmount" @input="calculateFee" type="number" class="dialog-input" placeholder="Enter amount" />
          <div>
            <p>Fee: ~ {{ fee.userFee }} (Refreshes every 1 min)</p>
            <p>Amount you will receive: ~ {{ fee.withdrawAmount }}</p>
            <p v-if="token.minWithdrawAmount">Min withdraw: {{ token.minWithdrawAmount }}</p>
            <p v-if="token.maxWithdrawAmount">Max withdraw: {{ token.maxWithdrawAmount }}</p>
          </div>
          <div class="dialog-buttons">
            <button @click="confirm" class="dialog-button confirm">Confirm</button>
            <button @click="cancel" class="dialog-button cancel">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        token: null,
        visible: false,
        title: '',
        message: '',
        inputAmount: 0,
        fee: {
          userFee: 0,
          withdrawAmount: 0,
        },
        resolve: null,
        reject: null
      };
    },
    methods: {
      open({ token }) {
        this.token = token
        this.visible = true;
        this.inputAmount = token.minWithdrawAmount || 1;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      confirm() {
        if (this.inputAmount > 0) {
          this.resolve({ amount: this.inputAmount});
          this.visible = false;
        }
      },
      cancel() {
        this.reject();
        this.visible = false;
      },
      calculateFee() {
        const gasPrice = this.token.gasPrice || 0;
        const gasPriceX = this.token.gasPriceX || 1;
        const gasLimit = this.token.gasLimit || 0;
        const minWithdrawFee = this.token.minWithdrawFee || 0;
        const tokenPrice = this.token.tokenPrice || 1;
        const amount = parseFloat(this.inputAmount) || 0;
  
        // Step 1: Calculate the transaction fee
        const transactionFee = gasPrice * gasPriceX * gasLimit;
  
        // Step 2: Calculate the user fee
        const userFee = Math.max(transactionFee * tokenPrice, minWithdrawFee);
  
        // Step 3: Calculate the withdrawable amount
        const withdrawAmount = amount - userFee;
  
        this.fee = {
          userFee,
          withdrawAmount: Math.max(withdrawAmount, 0), // Ensure withdraw amount doesn't go negative
        };
      }
    }
  };
  </script>
  
  <style scoped>
  </style>
  